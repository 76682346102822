import {RootStore, useRootStore} from "store/RootStore";
import {makeAutoObservable} from "mobx";
import Item from "store/data/shopping/Item";
import {Id} from "store/data/ID";

export class ShoppingStore {
    private root: RootStore;

    shoppingList: Item[] = [];

    addItem(s: string, productId: Id | null, note: string) {
        this.shoppingList.push(new Item(s, productId, note));
    }
    removeItem(id: Id) {
        const ix = this.shoppingList.findIndex(item => item.id === id);
        if (ix >= 0)
            this.shoppingList.splice(ix, 1);
    }

    constructor(root: RootStore) {
        this.root = root;

        makeAutoObservable(this);
    }
}

export function useShoppingStore() {
    return useRootStore().shoppingStore;
}
