import {AppBar, Box, Button, Container, FormControl, IconButton, Menu, MenuItem, Select, styled, Toolbar, Typography, withStyles} from "@mui/material";
import MarketFragment from "ui/components/MarketFragment";
import React, {useEffect, useState} from "react";
import {AddCircleOutline, ExpandMore} from "@mui/icons-material";
import theme from "theme";
import {observer} from "mobx-react-lite";
import {useDataStore} from "store/DataStore";
import GlobalAppBar from "ui/components/GlobalAppBar";
import {useUiStore} from "store/UiStore";

const GrowingSpace = styled("div")({
    flex: "1 1 auto"
});

const MarketAppBar: React.FC = observer(() => {
    const dataStore = useDataStore();
    const uiStore = useUiStore();

    const [marketMenu, setMarketMenu] = useState<HTMLElement | null>(null);

    // close menu if selectedMarket changed
    useEffect(() => setMarketMenu(null), [uiStore.selectedMarketIndex]);

    const marketItems = dataStore.markets.map((market, i) =>
        <MenuItem key={market.id} onClick={() => uiStore.setSelectedMarketIndex(i)}>{market.name}</MenuItem>);

    return <GlobalAppBar>
        <GrowingSpace />
        <Button color="inherit" sx={{whiteSpace: 'nowrap', flexShrink: 0}} onClick={(e) => dataStore.markets.length > 0 && setMarketMenu(e.currentTarget)}>
            <Typography variant="button" sx={{margin: theme.spacing(0, 0.5, 0, 1)}}>
                {dataStore.markets.filter((v, i) => i === uiStore.selectedMarketIndex)[0]?.name ?? <i>No market</i>}
            </Typography>
            {dataStore.markets.length > 0 && <ExpandMore fontSize="small"/>}
        </Button>
        <IconButton color="inherit" onClick={() => {
            // add a new market and select it, if no market was selected previously
            dataStore.addTestMarket();
            if (uiStore.selectedMarketIndex === undefined)
                uiStore.setSelectedMarketIndex(0);
        }}>
            <AddCircleOutline />
        </IconButton>
        <Menu
            anchorEl={marketMenu}
            open={Boolean(marketMenu)}
            onClose={(e) => setMarketMenu(null)}>
            {marketItems}
        </Menu>
    </GlobalAppBar>;
});

//

const MarketPage: React.FC = observer(() => {
    const uiStore = useUiStore();

    return <>
        <MarketAppBar />
        <Container maxWidth="xl" sx={{flex: "1", overflow: "hidden"}}>
            <Box sx={{height: "100%"}}>
                <MarketFragment marketIndex={uiStore.selectedMarketIndex}/>
            </Box>
        </Container>
    </>;
});

export default MarketPage;
