import {Id, ID} from "store/data/ID";
import {ISerializable} from "store/DataStore";
import {makeAutoObservable} from "mobx";

export class Product implements ISerializable {
    name: string;
    id: Id;

    constructor(name: string, id?: Id) {
        this.name = name;
        this.id = id ?? ID.create("product");

        makeAutoObservable(this);
    }

    serialize(): object {
        return {name: this.name, id: this.id};
    }

    static deserialize(data: any): Product {
        return new Product(data.name, data.id);
    }
}

export class ProductGroup implements ISerializable {
    name: string;
    id: Id;
    constructor(name: string, id?: Id) {
        this.name = name;
        this.id = id ?? ID.create("product-group");

        makeAutoObservable(this);
    }

    serialize(): object {
        return {name: this.name, id: this.id};
    }

    static deserialize(data: any): ProductGroup {
        return new ProductGroup(data.name, data.id);
    }
}
