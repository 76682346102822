import {makeAutoObservable} from "mobx";
import {Id, ID} from "store/data/ID";

export class Item {
    id: Id = ID.create("item");
    // linked product id
    productId: Id | null;
    // name in case there does not exist a linked product id, otherwise this is ignored
    name: string;
    note: string;

    constructor(name: string, productId?: Id|null, note?: string) {
        this.name = name;
        this.productId = productId ?? null;
        this.note = note ?? "";

        makeAutoObservable(this);
    }
}

export default Item;
