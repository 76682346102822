import React from "react";
import {observer} from "mobx-react-lite";
import {AppBar, Box, Button, Toolbar, Typography} from "@mui/material";
import {Link} from "react-router-dom";

type Props = {
    children?: React.ReactNode;
}
const GlobalAppBar: React.FC<Props> = observer(({children}) => {
    const pages = [
        {
            name: "Shopping",
            url: "/"
        }, {
            name: "Market",
            url: "/edit"
        }, {
            name: "Products",
            url: "/products"
        }
    ];
    return <AppBar position="static">
        <Toolbar>
            <Typography variant="h6" sx={{mr: 2, display: "flex", color: "inherit"}}>
                Test App bar
            </Typography>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
                {pages.map((page) => (
                    <Button
                        key={page.name}
                        sx={{ my: 2, color: "inherit", display: "block" }}
                        component={Link}
                        to={page.url}
                    >
                        {page.name}
                    </Button>
                ))}
            </Box>
            {children}
        </Toolbar>
    </AppBar>
});

export default GlobalAppBar;
