import {RootStore, useRootStore} from "store/RootStore";
import {makeAutoObservable} from "mobx";
import {IMapObject} from "store/data/Market";

export const PIXELS_PER_UNIT = 20;

export class UiStore {
    private root: RootStore;

    // -- rendering
    displayWidth: number = 0;
    displayHeight: number = 0;
    displayScaleX: number = 1;
    displayScaleY: number = 1;
    displayX: number = 0;
    displayY: number = 0;
    mapWidth: number = 50*PIXELS_PER_UNIT;
    mapHeight: number = 50*PIXELS_PER_UNIT;

    // -- toolbox
    selectedTool: string = "";

    // -- market page
    selectedMarketIndex: number | undefined = undefined;
    selectedObject: IMapObject | undefined = undefined;
    selectedObjectIndex: number | undefined = undefined;

    setDisplay(width: number, height: number) {
        this.displayWidth = width;
        this.displayHeight = height;

        this.setScale(this.displayWidth / this.mapWidth, this.displayWidth / this.mapHeight);
    }

    setMapSize(width: number, height: number) {
        this.mapWidth = width*PIXELS_PER_UNIT;
        this.mapHeight = height*PIXELS_PER_UNIT;
        this.setScale(this.displayWidth / this.mapWidth, this.displayWidth / this.mapHeight);
    }

    setScale(x: number, y: number) {
        this.displayScaleX = x;
        this.displayScaleY = y;
    }

    setDisplayPos(x: number, y: number) {
        this.displayX = x;
        this.displayY = y;
    }

    setSelectedTool(tool: string) {
        this.selectedTool = tool;
    }

    setSelectedMarketIndex(index: number) {
        if (index === this.selectedMarketIndex)
            return;
        this.selectedMarketIndex = index;
        this.selectedObject = undefined;
    }

    setObjectSelection(object: IMapObject|undefined, index: number) {
        this.selectedObject = object;
        this.selectedObjectIndex = index;
    }

    clearObjectSelection() {
        this.selectedObject = undefined;
        this.selectedObjectIndex = undefined;
    }

    constructor(root: RootStore) {
        this.root = root;

        makeAutoObservable(this);
    }
}

export function useUiStore() {
    return useRootStore().uiStore;
}

/**
 * Transforms logical units to pixels.
 * @param n Logical units.
 * @returns Pixels.
 */
export function L2P(n: number) {
    return n*PIXELS_PER_UNIT;
}

/**
 * Transforms pixels to logical units.
 * @param n Pixels.
 * @returns Logical units.
 */
export function P2L(n: number) {
    return n/PIXELS_PER_UNIT;
}
