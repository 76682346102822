import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {useElementSize} from "usehooks-ts";
import Konva from "konva";
import KonvaEventObject = Konva.KonvaEventObject;
import Vector2d = Konva.Vector2d;
import {Group, Layer, Line, Stage, Transformer} from "react-konva";

import {PIXELS_PER_UNIT, useUiStore} from "store/UiStore";
import {Size} from "type/GeneralTypes";


type TransformableGroupProps = {
    shapeProps: any,
    isSelected: any,
    onSelect: any,
    onChange: any,
    resizable?: boolean,
    children?: React.ReactNode
}

export const TransformableGroup: React.FC<TransformableGroupProps> = ({ shapeProps, isSelected, onSelect, onChange, resizable=true, children}) => {
    const shapeRef = useRef<Konva.Shape>(null);
    const trRef = useRef<Konva.Transformer>(null);
    const uiStore = useUiStore();

    React.useEffect(() => {
        if (isSelected && shapeRef.current !== null && trRef.current !== null) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer()!.batchDraw();
        }
    }, [isSelected]);

    const [draggable, setDraggable] = useState<boolean>(true);

    return (
        <React.Fragment>
            <Group
                onClick={(e) => e.evt.button === 0 && onSelect(e)}
                onTap={onSelect}
                ref={shapeRef}
                draggable={draggable}
                onMouseDown={(e) => setDraggable(e.evt.button === 0)}
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                }}
                opacity={/* FIXME */false && isSelected ? 0.5 : 1}
                {...shapeProps}
                onTransformEnd={(e) => {
                    // transformer is changing scale of the node
                    // and NOT its width or height
                    // but in the store we have only width and height
                    // to match the data better we will reset scale on transform end
                    const node = shapeRef.current!;

                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);

                    const posSize = snapToGrid({x: node.x(), y: node.y()}, {
                        width: node.width() * scaleX,
                        height: node.height() * scaleY
                    }, uiStore.mapWidth, uiStore.mapHeight);

                    onChange({
                        ...shapeProps,
                        x: posSize.pos.x,
                        y: posSize.pos.y,
                        width: posSize.size.width,
                        height: posSize.size.height,
                    });

                    // fix discrepancy between mobx store and konva
                    node.setPosition(posSize.pos);
                    node.setSize(posSize.size);
                }}
            >
                {children}
            </Group>
            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < PIXELS_PER_UNIT || newBox.height < PIXELS_PER_UNIT) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                    rotateEnabled={false}
                    resizeEnabled={resizable}
                    borderStrokeWidth={2}
                    keepRatio={false}
                />
            )}
        </React.Fragment>
    );
};

const scaleFactor = 1.1;

const snapToGrid = (pos: Konva.Vector2d, size: Size, mapWidth: number, mapHeight: number, alwaysFloor: boolean = false) => {
    const getNearest = (a: number, threshold: number) => {
        const halfThreshold = threshold/2;
        if (!alwaysFloor && (a % threshold) > halfThreshold)
            return a + (threshold - (a % threshold));
        else
            return a - (a % threshold);
    };
    const lastGridLineX = mapWidth;
    const lastGridLineY = mapHeight;


    // snap to grid
    pos.x = getNearest(pos.x, PIXELS_PER_UNIT);
    pos.y = getNearest(pos.y, PIXELS_PER_UNIT);
    size.width = getNearest(size.width, PIXELS_PER_UNIT);
    size.height = getNearest(size.height, PIXELS_PER_UNIT);

    // out of bounds check
    pos.x = Math.max(pos.x, 0);
    pos.y = Math.max(pos.y, 0);
    if ((pos.x + size.width) > lastGridLineX)
        pos.x = lastGridLineX - (Math.ceil((size.width) / PIXELS_PER_UNIT) * PIXELS_PER_UNIT);
    if ((pos.y + size.height) > lastGridLineY)
        pos.y = lastGridLineY - (Math.ceil((size.height) / PIXELS_PER_UNIT) * PIXELS_PER_UNIT);

    // set position
    return {pos, size};
}

type ScreenProps = {
    clearSelection?: (e: KonvaEventObject<MouseEvent>) => void,
    onStageClick?: (relativePos: Vector2d, e: KonvaEventObject<MouseEvent>) => void,
    onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>
    children?: React.ReactNode,
};
export const Screen: React.FC<ScreenProps> = observer(({clearSelection, onStageClick, onKeyDown, children}) => {
    const uiStore = useUiStore();
    const [containerRef, displaySize] = useElementSize();
    const displayWidth = displaySize.width;
    const displayHeight = displaySize.height;

    const _snapToGrid = (pos: Vector2d, size: Size, alwaysFloor: boolean = false) =>
        snapToGrid(pos, size, uiStore.mapWidth, uiStore.mapHeight, alwaysFloor);

    useEffect(() => {
        uiStore.setDisplay(displayWidth, displayHeight);
    }, [displayWidth, displayHeight]);

    const [panning, setPanning] = useState<boolean>(false);
    const [panningStartPos, setPanningStartPos] = useState<Vector2d>({x: 0, y:0});

    const onMouseDown = (e: KonvaEventObject<MouseEvent>) => {
        // middle-click to pan
        if (e.evt.button === 1) {
            setPanningStartPos(e.target.getStage()!.pointerPos!);
            setPanning(true);
        }
        if (e.target === e.target.getStage()) {
            const snappedPos = _snapToGrid(e.target.getStage()!.getRelativePointerPosition(), {width: 1, height: 1}, true).pos;
            clearSelection?.(e);
            onStageClick?.(snappedPos, e);
        }
    };

    const onMouseUp = (e: KonvaEventObject<MouseEvent>) => {
        if (e.evt.button === 1)
            setPanning(false);
    }

    const onMouseMove = (e: KonvaEventObject<MouseEvent>) => {
        if (panning) {
            const pointer = e.target.getStage()!.pointerPos!;
            uiStore.setDisplayPos(uiStore.displayX + pointer.x - panningStartPos.x, uiStore.displayY + pointer.y - panningStartPos.y);
            setPanningStartPos(pointer);
        }
    };

    const onDragMove = (e: KonvaEventObject<DragEvent>) => {
        const pos = e.target.position();
        const size = e.target.size();
        e.target.position(_snapToGrid(pos, size).pos);
    };

    const scaleViewport = (e: KonvaEventObject<WheelEvent>) => {
        // stop default scrolling
        e.evt.preventDefault();

        let direction = e.evt.deltaY > 0 ? -1 : 1;
        const oldScale = uiStore.displayScaleX;
        const stage = e.target.getStage()!;
        const pointer = stage.pointerPos!;

        const newScale = direction > 0 ? oldScale * scaleFactor : oldScale / scaleFactor;

        const mousePointTo = {
            x: (pointer.x - stage.x()) / oldScale,
            y: (pointer.y - stage.y()) / oldScale,
        };
        const newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale,
        };

        uiStore.setScale(newScale, newScale);
        uiStore.setDisplayPos(newPos.x, newPos.y);
    }

    const gridLineFactory = (key: number, x: number, y: number, width: number, height: number) =>
        <Line key={key} x={x} y={y} points={[0, 0, width, height]} stroke={"lightgray"} strokeWidth={1} listening={false}/>

    const snapGridX = Array(Math.floor(uiStore.mapWidth / PIXELS_PER_UNIT) + 1).fill(0).map((x, i) =>
        gridLineFactory(i, i*PIXELS_PER_UNIT, 0, 0, uiStore.mapHeight));
    const snapGridY = Array(Math.floor(uiStore.mapHeight / PIXELS_PER_UNIT) + 1).fill(0).map((x, i) =>
        gridLineFactory(i, 0, i*PIXELS_PER_UNIT, uiStore.mapWidth, 0));

    return <div className="container" ref={containerRef} onKeyDown={onKeyDown} tabIndex={-1}>
        <Stage x={uiStore.displayX} y={uiStore.displayY} width={uiStore.displayWidth} height={uiStore.displayHeight}
               scale={{x: uiStore.displayScaleX, y: uiStore.displayScaleY}}
               onMouseDown={onMouseDown} onMouseUp={onMouseUp} onMouseMove={onMouseMove} onWheel={scaleViewport}>
            <Layer onDragMove={onDragMove}>
                {snapGridX}
                {snapGridY}
                {children}
            </Layer>
        </Stage>
    </div>;
});
