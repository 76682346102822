import React from "react";
import {DataStore} from "store/DataStore";
import {UiStore} from "store/UiStore";
import {ShoppingStore} from "store/ShoppingStore";


export class RootStore {
    dataStore: DataStore;
    shoppingStore: ShoppingStore;
    uiStore: UiStore;

    constructor() {
        console.log("created new RootStore");

        this.dataStore = new DataStore(this);
        this.shoppingStore = new ShoppingStore(this);
        this.uiStore = new UiStore(this);
    }
}

const RootStoreContext = React.createContext<RootStore | null>(null);

export function useRootStore() {
    const context = React.useContext(RootStoreContext);
    if (!context) {
        throw new Error("useRootStore must be used within RootStoreProvider");
    }
    return context;
}

type Props = {
    children?: React.ReactNode;
}

const rootStore = new RootStore();

export const RootStoreProvider : React.FunctionComponent<Props> = ({children}) => {
    return <RootStoreContext.Provider value={rootStore}>{children}</RootStoreContext.Provider>;
}
