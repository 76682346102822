import React, {ReactElement, useEffect, useState} from "react";

import {observer} from "mobx-react-lite";
import {useDataStore} from "store/DataStore";
import {IMapObject, Market, Shelf} from "store/data/Market";

import {Screen} from "ui/components/Canvas";
import {P2L, useUiStore} from "store/UiStore";
import Konva from "konva";

import "./MarketView.css";
import {
    Box,
    Button, Chip,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {AddCircleOutline, Apps, Clear, GridView, Login, Logout, PointOfSale, SaveAlt, SvgIconComponent, UploadFile} from "@mui/icons-material";
import theme from "theme";
import {CheckoutComponent, EntryComponent, ExitComponent, ShelfComponent, TransformableComponentProps, WallComponent} from "ui/components/MarketObjects";
import Vector2d = Konva.Vector2d;


type ToolboxItemProps = {
    name: string,
    Icon: SvgIconComponent,
    onClick?: () => void,
    children?: React.ReactNode
}

const ToolboxItem: React.FC<ToolboxItemProps> = observer(({name, onClick, Icon, children}) => {
    const uiStore = useUiStore();

    const isPressed = () => uiStore.selectedTool === name;

    return <Grid sx={{backgroundColor: isPressed() ? theme.palette.primary.light : ""}}>
        <IconButton color={"inherit"} title={name} onClick={() => {
            uiStore.setSelectedTool(name);
            onClick?.()
        }}
        >
            <Icon fontSize={"large"}/>
            {children}
        </IconButton>
    </Grid>;
});

type ToolboxProps = {}
const Toolbox: React.FC<ToolboxProps> = () => {
    const uiStore = useUiStore();
    useEffect(() => uiStore.setSelectedTool("Nothing"), [uiStore]);

    return <Paper>
        <Grid container p={1}>
            <ToolboxItem name="Shelf" Icon={GridView}></ToolboxItem>
            <ToolboxItem name="Wall" Icon={Apps}></ToolboxItem>
            <ToolboxItem name="Checkout" Icon={PointOfSale}></ToolboxItem>
            <ToolboxItem name="Entry" Icon={Login}></ToolboxItem>
            <ToolboxItem name="Exit" Icon={Logout}></ToolboxItem>
            <ToolboxItem name="Nothing" Icon={Clear}></ToolboxItem>
        </Grid></Paper>
        ;
};

type ObjectPropertiesProps<T> = {
    title: string
    tableProps: TableProp<T>[]
    object: T
}
type TableProp<T> = [
    string, (obj: T) => React.ReactNode
];
const ObjectProperties = observer(<T extends {}>({title, tableProps, object}: ObjectPropertiesProps<T>) => {
    return <Paper><Box sx={{padding: theme.spacing(1, 1, 1, 1)}}>
        <Typography variant={"h6"}>{title}</Typography>
        <TableContainer component={Box}>
            <Table size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell width={"20%"}>Property</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableProps.map((p, i) =>
                        <TableRow key={i}>
                            <TableCell>{p[0]}</TableCell>
                            <TableCell>{p[1](object)}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    </Box></Paper>;
});

type MapObjectPropertiesProps<T extends IMapObject> = {
    object: T,
    additionalProperties?: TableProp<T>[],
}
const MapObjectProperties = observer(<T extends IMapObject>({object, additionalProperties}: MapObjectPropertiesProps<T>) => {
    const tableProps: TableProp<T>[] = [
        ["Type", (o) => o.type],
        ["Position", (o) => `(${o.obj.position.x}, ${o.obj.position.y})`],
        ["Size", (o) => `${o.obj.size.width}x${o.obj.size.height}`],
        ...(additionalProperties ?? [])
    ];
    return <ObjectProperties title="Object Properties" tableProps={tableProps} object={object} />
});

type ShelfPropertiesProps = {
    object: Shelf
}
const ShelfProperties: React.FC<ShelfPropertiesProps> = observer(({object}) => {
    const [productAnchorEl, setProductAnchorEl] = React.useState<null | HTMLElement>(null);
    const [productGroupAnchorEl, setProductGroupAnchorEl] = React.useState<null | HTMLElement>(null);
    const openProduct = Boolean(productAnchorEl);
    const openProductGroup = Boolean(productGroupAnchorEl);
    const handleOpenProduct = (event: React.MouseEvent<HTMLElement>) => setProductAnchorEl(event.currentTarget);
    const handleOpenProductGroup = (event: React.MouseEvent<HTMLElement>) => setProductGroupAnchorEl(event.currentTarget);
    const handleCloseProduct = () => setProductAnchorEl(null);
    const handleCloseProductGroup = () => setProductGroupAnchorEl(null);

    const dataStore = useDataStore();

    const missingProducts = dataStore.products.filter((product) => object.productIds.indexOf(product.id) === -1);
    const missingProductGroups = dataStore.productGroups.filter((group) => object.productGroupIds.indexOf(group.id) === -1);

    const tableProps: TableProp<Shelf>[] = [
        ["Products", (o) => <>
                {o.productIds.map((productId) =>
                    <Chip key={productId} label={dataStore.findProduct(productId)?.name} onDelete={() => o.removeProductId(productId)}/>
                )}
                <IconButton color="inherit" onClick={handleOpenProduct} disabled={missingProducts.length === 0}><AddCircleOutline /></IconButton>
                <Menu anchorEl={productAnchorEl} open={openProduct} onClose={handleCloseProduct}>
                    {missingProducts.map((product) =>
                        <MenuItem key={product.id} value={product.id} onClick={() => {o.addProductId(product.id); handleCloseProduct()}}>
                            {product.name}
                        </MenuItem>)}
                </Menu>
            </>
        ],
        ["Product Groups", (o) => <>
                {o.productGroupIds.map((productGroupId) =>
                    <Chip key={productGroupId} label={dataStore.findProductGroup(productGroupId)?.name} onDelete={() => o.removeProductGroupId(productGroupId)}/>
                )}
                <IconButton color="inherit" onClick={handleOpenProductGroup} disabled={missingProductGroups.length === 0}><AddCircleOutline /></IconButton>
                <Menu anchorEl={productGroupAnchorEl} open={openProductGroup} onClose={handleCloseProductGroup}>
                    {missingProductGroups.map((group) =>
                        <MenuItem key={group.id} value={group.id} onClick={() => {o.addProductGroupId(group.id); handleCloseProductGroup()}}>
                            {group.name}
                        </MenuItem>)}
                </Menu>
            </>
        ],
    ];
    return <MapObjectProperties object={object} additionalProperties={tableProps}/>
});
type MarketPropertiesProps = {
    market: Market
}
const MarketProperties: React.FC<MarketPropertiesProps> = observer(({market}) => {
    const tableProps: TableProp<Market>[] = [
        ["Name", (o) => o.name],
        ["Size", (o) => `${o.size.width}x${o.size.height}`],
    ];
    return <ObjectProperties title="Market Properties" tableProps={tableProps} object={market} />
});

function ResolveMapObjectProperties(object: IMapObject) {
    if (object.type === "Shelf")
        return <ShelfProperties object={object as Shelf}/>;
    return <MapObjectProperties object={object}/>;
}

type ControllerProps = {
    market?: Market,
};
const Controller: React.FC<ControllerProps> = observer(({market}) => {
    const dataStore = useDataStore();
    const uiStore = useUiStore();

    const MapObjectPropertiesComponent = uiStore.selectedObject ? ResolveMapObjectProperties(uiStore.selectedObject) : null;

    return <Box sx={{flex: "1"}} p={2}>
        <Toolbox/>
        <br/>
        {market && (uiStore.selectedObject ? MapObjectPropertiesComponent : <MarketProperties market={market} />)}

        <br/>
        <Button fullWidth={true} variant="contained" startIcon={<UploadFile/>} onClick={() => dataStore.exportAll()}>Save to local storage</Button>
        <br/>
        <br/>
        <Button fullWidth={true} variant="contained" startIcon={<SaveAlt/>} onClick={() => dataStore.importAll()}>Load from local storage</Button>
    </Box>;
});

type DisplayProps = {
    market?: Market,
};

const Display: React.FC<DisplayProps> = observer(({market}) => {
    const mapObjectsMap: Record<string, { component: React.FC<any>, data: IMapObject[] | undefined, adder: ((x: number, y: number) => void) | undefined }> = {
        "Shelf": {component: ShelfComponent, data: market?.shelves, adder: market?.addShelf.bind(market)},
        "Wall": {component: WallComponent, data: market?.walls, adder: market?.addWall.bind(market)},
        "Checkout": {component: CheckoutComponent, data: market?.checkouts, adder: market?.addCheckout.bind(market)},
        "Entry": {component: EntryComponent, data: market?.entries, adder: market?.addEntry.bind(market)},
        "Exit": {component: ExitComponent, data: market?.exits, adder: market?.addExit.bind(market)},
    };

    const uiStore = useUiStore();

    // update screen when market size changes
    useEffect(() => {
        if (market !== undefined)
            uiStore.setMapSize(market.size.width, market!.size.height);
    }, [uiStore, market?.size]);

    // create list of all contained map objects as components
    const components = [market?.shelves, market?.walls, market?.checkouts, market?.entries, market?.exits]
        .reduce<ReactElement[]>((components, objs: IMapObject[] | undefined) => {
            components.push(...objs?.map((obj, i) => {
                const Component: React.FC<TransformableComponentProps<any>> = mapObjectsMap[obj.type].component;
                return <Component key={obj.obj.id} object={obj}
                                  selected={obj.type === uiStore.selectedObject?.type && i === uiStore.selectedObjectIndex}
                                  onSelect={() => uiStore.setObjectSelection(obj, i)}/>
            }) ?? []);
            return components;
        }, []);

    const performToolAction = (pos: Vector2d) => mapObjectsMap[uiStore.selectedTool]?.adder?.(P2L(pos.x), P2L(pos.y));

    return <div className="MarketView-Display">
        <p>{uiStore.displayWidth}px <i>x</i> {uiStore.displayHeight}px ({uiStore.displayScaleX}x). Offset ({uiStore.displayX}, {uiStore.displayY})</p>
        {market ? <Screen clearSelection={(e) => uiStore.clearObjectSelection()}
                          onStageClick={(pos, e) => e.evt.button === 0 && performToolAction(pos)}
                          onKeyDown={(e) => {
                              if (e.key === "Delete")
                                  if (uiStore.selectedObject !== undefined) {
                                      market?.deleteObject(uiStore.selectedObject!);
                                      uiStore.clearObjectSelection();
                                  }
                          }
                          }>
            {components}
        </Screen> : <p className="placeholder">Select a market at the top.</p>}
    </div>;
});

type MarketFragmentProps = {
    marketIndex: number | undefined,
}
const MarketFragment: React.FC<MarketFragmentProps> = observer(({marketIndex}) => {
    const dataStore = useDataStore();
    const curMarket = dataStore.markets[marketIndex!] ?? undefined;

    return <>
        <div className="MarketView">
            <Controller market={curMarket} />
            <Display market={curMarket} />
        </div>
    </>;
});

export default MarketFragment;
