import React, {Key, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {
    Autocomplete,
    Box,
    Button,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack, StackProps, styled,
    TextField,
    Typography
} from "@mui/material";

// icons
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import Delete from "@mui/icons-material/Delete";


type ItemGetter<T> = (item: T) => {
    key: Key,
    texts: {
        primary: string | React.ReactNode,
        secondary: string | React.ReactNode,
    }[]
    icon?: React.ReactNode
};

type EditableListProps<T, U> = {
    label: string;
    data: T[];
    onDelete: (item: T) => void;
    onAdd: (s: string, autoCompleteOption: U | null) => boolean | void;
    itemGetter: ItemGetter<T>;
    children?: React.ReactNode;
    orientation?: "top" | "bottom";
    autoComplete?: boolean;
    autoCompleteData?: U[];
    autoCompleteLabel?: (option: U) => string;
    trim?: boolean;
    additionalInputComponents?: React.ReactNode[],
    editableItem?: boolean;
    onEdit?: (item: T) => [string, U | null];
}

const EditableList = observer(<T extends {}, U extends {}>({
       label, data, onDelete, onAdd, itemGetter, children, orientation = "bottom", autoComplete = false, autoCompleteData = [], autoCompleteLabel = (() => ""),
       trim = true, additionalInputComponents = [], editableItem = false, onEdit = undefined
   }: EditableListProps<T, U>) => {
    const [selectValue, setSelectValue] = useState<U | null>(null);
    const [inputValue, setInputValue] = useState("");
    const inputNode = useRef<HTMLInputElement>(null);

    const lookUpOption = (o: string | U) => {
        const option = autoCompleteData.indexOf(o as U);
        if (option >= 0)
            return autoCompleteData[option];
        return undefined;
    };

    const lookUpOptionLabel = (label: string) => {
        if (trim)
            label = label.trim();
        for (const option of autoCompleteData) {
            if (autoCompleteLabel(option) === label)
                return option;
        }
        return undefined;
    }

    const addNewItem = (e: any) => {
        let v = selectValue;
        // lookup inputValue in autoCompleteData, if it matches an option, use this for callback
        if (autoComplete)
            v = lookUpOptionLabel(inputValue) ?? v;

        if (onAdd(inputValue, v) !== false) {
            setSelectValue(null);
            setInputValue("");
        }

        // focus input element again
        inputNode.current?.focus();

        e.preventDefault();
    };

    const editItem = (item: T) => {
        const [input, select] = onEdit!(item);
        setInputValue(input);
        setSelectValue(select);
    };

    if (editableItem && onEdit === undefined)
        throw new Error("Must specify onEdit for editable List");

    let inputComponent;

    if (autoComplete) {
        inputComponent = <Autocomplete sx={{flex: 1}} freeSolo options={autoCompleteData}
                                       getOptionLabel={(o) => {
                                           const option = lookUpOption(o);
                                           if (option !== undefined)
                                               return autoCompleteLabel(option);
                                           return o as string;
                                       }}
                                       value={selectValue} onChange={(e, v) => setSelectValue(v as U)}
                                       inputValue={inputValue} onInputChange={(e, v) => setInputValue(v)}
                                       renderInput={(params) => <TextField {...params} inputRef={inputNode} variant="outlined" size={"small"} label={label} />}
                        />;
    } else {
        inputComponent = <TextField variant="outlined" size={"small"} label={label} sx={{flex: 1}} value={inputValue}
                                      onChange={(e) => setInputValue(e.target.value)} />;
    }

    const input = <Box component={"form"} sx={{width: "100%"}} onSubmit={addNewItem}>
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            p={1}
            spacing={1}
        >
            {inputComponent}
            {additionalInputComponents}
            <Button type="submit" variant="contained" endIcon={<AddCircleOutline />}>Add</Button>
        </Stack>
    </Box>;

    const listItemButton = (item: T, children: React.ReactNode) =>
        editableItem ? <ListItemButton onClick={() => editableItem && editItem(item)}>{children}</ListItemButton>
        : <>{children}</>;

    const FlexStack = styled(Stack)<StackProps>({display: "flex", "& > *": {flex: "1"}});

    return <>
            <Typography variant="h5" p={1}>{children}</Typography>
            {orientation === "top" && <>{input} <Divider/></>}
            <List>
                {data.length > 0 ? data.map((item, i) =>
                    <ListItem key={itemGetter(item).key} secondaryAction={
                        <IconButton edge="end" onClick={() => onDelete(item)}><Delete /></IconButton>}>
                        {listItemButton(item, <>
                            {itemGetter(item).icon !== undefined ? <ListItemIcon>{itemGetter(item).icon}</ListItemIcon> : <></>}
                            <ListItemText
                                // prevent nesting div within a p/span
                                primaryTypographyProps={{component: "div"}} secondaryTypographyProps={{component: "div"}}
                                primary={
                                    <FlexStack direction="row">{itemGetter(item).texts.map(({primary, secondary}, i) =>
                                    <span key={`${itemGetter(item).key}${i}`}>{primary}</span>)}
                                </FlexStack>}
                              secondary={
                                    <FlexStack direction="row">{itemGetter(item).texts.map(({primary, secondary}, i) =>
                                    <span key={`${itemGetter(item).key}${i}`}>{secondary}</span>)}
                                </FlexStack>}
                            />
                        </>)}
                    </ListItem>
                ) : <ListItem key={-1}><ListItemText sx={{textAlign: "center"}}>
                        <Typography variant="body1"><i>Empty list</i></Typography></ListItemText>
                    </ListItem>}
            </List>
            {orientation === "bottom" && <><Divider /> {input}</>}
        </>;
});

export default EditableList;
