import React, {useRef, useState} from "react";
import {
    Box,
    Container, Paper, TextField, Typography,
} from "@mui/material";
import {observer} from "mobx-react-lite";

// icons
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';

import GlobalAppBar from "ui/components/GlobalAppBar";
import EditableList from "ui/components/EditableList";
import {useShoppingStore} from "store/ShoppingStore";
import Item from "store/data/shopping/Item";
import {useDataStore} from "store/DataStore";

const ShoppingList: React.FC = observer(() => {
    const shoppingStore = useShoppingStore();
    const dataStore = useDataStore();

    const [note, setNote] = useState("");

    const noteInput = useRef<HTMLInputElement>(null);

    // only display products in auto complete that have not been added already
    const autoCompleteData = dataStore.products.slice();
    for (let item of shoppingStore.shoppingList) {
        if (item.productId !== null) {
            const ix = autoCompleteData.findIndex((p) => p.id === item.productId);
            if (ix >= 0)
                autoCompleteData.splice(ix, 1);
        }
    }

    return <Box p={2}>
        <Paper>
            <EditableList label={"Product"} data={shoppingStore.shoppingList} itemGetter={(item: Item) => ({
                                key: item.id,
                                texts: [{
                                    primary: item.name,
                                    secondary: (item.productId !== null ? dataStore.findProduct(item.productId)?.id : null) ?? <NotListedLocationIcon fontSize={"inherit"}/>,
                                }, {
                                    primary: item.note,
                                    secondary: "",
                                }]
                            })}
                          onAdd={(s, option) => {shoppingStore.addItem(s, option?.id ?? null, note); setNote("")}}
                          onDelete={item => shoppingStore.removeItem(item.id)}
                          editableItem={true}
                          onEdit={item => {
                              shoppingStore.removeItem(item.id);
                              setNote(item.note);
                              noteInput.current?.focus();
                              return [item.name, (item.productId !== null ? dataStore.findProduct(item.productId) : null) ?? null]
                          }}
                          orientation={"top"}
                          autoComplete={true}
                          autoCompleteData={autoCompleteData}
                          autoCompleteLabel={product => product.name}
                          additionalInputComponents={[
                              <TextField inputRef={noteInput} key="1" variant="outlined" size={"small"} label={"Note"}
                                         sx={{flex: 1}} value={note} onChange={(e) => setNote(e.target.value)} />
                          ]}
                    >
                Shopping List
            </EditableList>
        </Paper>
    </Box>;
});

const Route: React.FC = () => {
    return <Box p={2}>
        <Paper>
            <Typography variant="body1" p={1}>This is the route</Typography>
        </Paper>
    </Box>;
};

const GoShoppingPage: React.FC = () => {
    return <>
        <GlobalAppBar/>
        <Container maxWidth="xl" sx={{flex: "1", overflow: "hidden"}}>
            <Box sx={{height: "100%"}}>
                <ShoppingList />
                <Route />
            </Box>
        </Container>
    </>;
};

export default GoShoppingPage;
