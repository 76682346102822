import {Coordinate, Rotation, Size} from "type/GeneralTypes";
import {makeAutoObservable} from "mobx";
import {P2L} from "store/UiStore";
import {ID, Id} from "store/data/ID";

export interface IMapObject {
    type: string;
    obj: MapObject;
    serialize(): object;
}

export class MapObject {
    id: Id;
    position: Coordinate = {x: 0, y: 0};
    rotation: Rotation = 0.0;
    size: Size = {width: 5, height: 1};

    setPosition(newPosition: Coordinate) {
        this.position = newPosition;
    }

    /**
     * Sets position in pixels.
     * @param newPosition Position in pixels
     */
    setPositionP(newPosition: Coordinate) {
        this.position = {x: P2L(newPosition.x), y: P2L(newPosition.y)};
    }

    setSize(newSize: Size) {
        this.size = newSize;
    }

    /**
     * Sets size in pixels.
     * @param newSize Size in pixels.
     */
    setSizeP(newSize: Size) {
        this.size = {width: P2L(newSize.width), height: P2L(newSize.height)};
    }

    constructor(id: Id, position?: Coordinate, rotation?: Rotation, size?: Size) {
        this.id = id;
        if (position)
            this.position = position;
        if (rotation)
            this.rotation = rotation;
        if (size)
            this.size = size;

        makeAutoObservable(this);
    }

    serialize(): object {
        return {id: this.id, position: this.position, rotation: this.rotation, size: this.size};
    }

    static deserialize(data: any, type: string): MapObject {
        return new MapObject(data.id ?? ID.create(type), data.position, data.rotation, data.size);
    }
}

export class Shelf implements IMapObject {
    static type = "Shelf";
    type = Shelf.type;
    obj: MapObject;
    // list of assigned Products
    productIds: Id[];
    productGroupIds: Id[];

    constructor(position?: Coordinate, rotation?: Rotation, size?: Size, productIds?: Id[], productGroupIds?: Id[],
                obj?: MapObject) {
        this.obj = obj ?? new MapObject(ID.create(Shelf.type), position, rotation, size);
        this.productIds = productIds ?? [];
        this.productGroupIds = productGroupIds ?? [];

        makeAutoObservable(this);
    }

    addProductId(id: Id) {
        this.productIds.push(id);
    }

    removeProductId(id: Id) {
        const ix = this.productIds.indexOf(id);
        if (ix >= 0)
            this.productIds.splice(ix, 1);
    }
    addProductGroupId(id: Id) {
        this.productGroupIds.push(id);
    }

    removeProductGroupId(id: Id) {
        const ix = this.productGroupIds.indexOf(id);
        if (ix >= 0)
            this.productGroupIds.splice(ix, 1);
    }

    serialize(): object {
        return {obj: this.obj.serialize(), productIds: this.productIds, productGroupIds: this.productGroupIds};
    }

    static deserialize(data: any): Shelf {
        const obj = MapObject.deserialize(data.obj, Shelf.type);
        return new Shelf(undefined, undefined, undefined, data.productIds, data.productGroupIds,
            obj);
    }
}
export class Wall implements IMapObject{
    static type = "Wall";
    type = Wall.type;
    obj: MapObject;

    constructor(position?: Coordinate, rotation?: Rotation, size?: Size, obj?: MapObject) {
        this.obj = obj ?? new MapObject(ID.create(Wall.type), position, rotation, size);

        makeAutoObservable(this);
    }

    serialize(): object {
        return {obj: this.obj.serialize()};
    }

    static deserialize(data: any): Wall {
        const obj = MapObject.deserialize(data.obj, Wall.type);
        return new Wall(undefined, undefined, undefined, obj);
    }
}

export class Checkout implements IMapObject{
    static type = "Checkout";
    type = Checkout.type;
    obj: MapObject;

    constructor(position?: Coordinate, rotation?: Rotation, obj?: MapObject) {
        this.obj = obj ?? new MapObject(ID.create(Checkout.type), position, rotation, {width: 1, height: 1});

        makeAutoObservable(this);
    }

    serialize(): object {
        return {obj: this.obj.serialize()};
    }

    static deserialize(data: any): Checkout {
        const obj = MapObject.deserialize(data.obj, Checkout.type);
        return new Checkout(undefined, undefined, obj);
    }
}

export class Entry implements IMapObject{
    static type = "Entry";
    type = Entry.type;
    obj: MapObject;

    constructor(position?: Coordinate, rotation?: Rotation, obj?: MapObject) {
        this.obj = obj ?? new MapObject(ID.create(Entry.type), position, rotation, {width: 1, height: 1});

        makeAutoObservable(this);
    }

    serialize(): object {
        return {obj: this.obj.serialize()};
    }

    static deserialize(data: any): Entry {
        const obj = MapObject.deserialize(data.obj, Entry.type);
        return new Entry(undefined, undefined, obj);
    }
}

export class Exit implements IMapObject{
    static type = "Exit";
    type = Exit.type;
    obj: MapObject;

    constructor(position?: Coordinate, rotation?: Rotation, obj?: MapObject) {
        this.obj = obj ?? new MapObject(ID.create(Exit.type), position, rotation, {width: 1, height: 1});

        makeAutoObservable(this);
    }

    serialize(): object {
        return {obj: this.obj.serialize()};
    }

    static deserialize(data: any): Exit {
        const obj = MapObject.deserialize(data.obj, Exit.type);
        return new Exit(undefined, undefined, obj);
    }
}

export class Market {
    name: string;
    size: Size = {width: 50, height: 50};
    shelves: Shelf[] = [];
    walls: Wall[] = [];
    checkouts: Checkout[] = [];
    entries: Checkout[] = [];
    exits: Checkout[] = [];
    id: Id;

    changeName(newName: string) {
        this.name = newName;
    }

    addShelf(x: number, y: number) {
        this.shelves.push(new Shelf({x, y}));
    }

    addWall(x: number, y: number) {
        this.walls.push(new Wall({x, y}));
    }

    addCheckout(x: number, y: number) {
        this.checkouts.push(new Checkout({x, y}));
    }
    addEntry(x: number, y: number) {
        this.entries.push(new Entry({x, y}));
    }
    addExit(x: number, y: number) {
        this.exits.push(new Exit({x, y}));
    }

    deleteObject(obj: IMapObject) {
        let allObjs: IMapObject[][] = [this.shelves, this.walls, this.checkouts, this.entries, this.exits];
        for (let objs of allObjs) {
            let ix = objs.indexOf(obj);
            if (ix >= 0) {
                objs.splice(ix, 1);
                return;
            }
        }
    }

    serialize() {
        return {
            id: this.id,
            name: this.name,
            size: this.size,
            shelves: this.shelves.map((o) => o.serialize()),
            walls: this.walls.map((o) => o.serialize()),
            checkouts: this.checkouts.map((o) => o.serialize()),
            entries: this.entries.map((o) => o.serialize()),
            exits: this.exits.map((o) => o.serialize()),
        }
    }

    static deserialize(s: any): Market {
        const size: Size = s.size ?? {width: 50, height: 50};
        const shelves: Shelf[] = s.shelves?.map((s: any) => Shelf.deserialize(s));
        const walls: Wall[] = s.walls?.map((s: any) => Wall.deserialize(s));
        const checkouts: Checkout[] = s.checkouts?.map((s: any) => Checkout.deserialize(s));
        const entries: Entry[] = s.entries?.map((s: any) => Entry.deserialize(s));
        const exits: Exit[] = s.exits?.map((s: any) => Exit.deserialize(s));
        return new Market(s.name, size, shelves, walls, checkouts, entries, exits, s.id);
    }

    constructor(name: string, size?: Size, shelves?: Shelf[], walls?: Wall[], checkouts?: Checkout[], entries?: Entry[],
                exits?: Exit[], id?: Id) {
        this.name = name;
        if (size)
            this.size = size;
        if (shelves)
            this.shelves = shelves;
        if (walls)
            this.walls = walls;
        if (checkouts)
            this.checkouts = checkouts;
        if (entries)
            this.entries = entries;
        if (exits)
            this.exits = exits;
        this.id = id ?? ID.create("market");

        makeAutoObservable(this);
    }
}
