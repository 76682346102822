import React from "react";
import {Checkout, Entry, Exit, IMapObject, Shelf, Wall} from "store/data/Market";
import {observer} from "mobx-react-lite";
import {TransformableGroup} from "ui/components/Canvas";
import {Image, Rect, Text} from "react-konva";
import useImage from "use-image";
import PointOfSaleSVG from "@material-design-icons/svg/filled/point_of_sale.svg";
import EntrySVG from "@material-design-icons/svg/filled/login.svg";
import ExitSVG from "@material-design-icons/svg/filled/logout.svg";
import {L2P} from "store/UiStore";
import {Coordinate, Size} from "type/GeneralTypes";

export type TransformableComponentProps<T extends IMapObject> = {
    object: T,
    title?: string,
    selected: boolean,
    onSelect: () => void,
    fill?: string,
    resizable?: boolean,
    iconURL?: string,
};
const TransformableComponent = observer(<T extends IMapObject>({object, title, selected, onSelect, fill, resizable, iconURL}: TransformableComponentProps<T>) => {
    let icon;
    if (iconURL)
        [icon] = useImage(iconURL);
    const IMG_MARGIN = 2;

    const obj = object.obj;
    const size: Size = {width: L2P(obj.size.width), height: L2P(obj.size.height)};
    const pos: Coordinate = {x: L2P(obj.position.x), y: L2P(obj.position.y)};
    return <TransformableGroup shapeProps={
        {x: pos.x, y: pos.y, width: size.width, height: size.height}}
                               isSelected={selected} onSelect={onSelect}
                               onChange={(newAttr: any) => {
                                   obj.setPositionP({x: newAttr.x, y: newAttr.y});
                                   if (newAttr.width && newAttr.height)
                                       obj.setSizeP({width: newAttr.width, height: newAttr.height});
                               }} resizable={resizable}>
        <Rect width={size.width} height={size.height} fill={fill} fillstrokeWidth={1} stroke="black" shadowEnabled={selected}/>
        <Image image={icon} width={size.width-IMG_MARGIN*2} height={size.height-IMG_MARGIN*2} x={IMG_MARGIN} y={IMG_MARGIN} listening={false} />
        <Text text={title} width={size.width} height={size.height} align="center" verticalAlign="middle" listening={false}/>
    </TransformableGroup>;
});
export const ShelfComponent: React.FC<TransformableComponentProps<Shelf>> = observer((props) => {
    return <TransformableComponent {...props} fill="cyan" title="Shelf"/>;
});
export const WallComponent: React.FC<TransformableComponentProps<Wall>> = observer((props) => {
    return <TransformableComponent {...props} fill="magenta" title="Wall"/>;
});
export const CheckoutComponent: React.FC<TransformableComponentProps<Checkout>> = observer((props) => {
    return <TransformableComponent {...props} fill="orange" resizable={false} iconURL={PointOfSaleSVG}/>;
});
export const EntryComponent: React.FC<TransformableComponentProps<Entry>> = observer((props) => {
    return <TransformableComponent {...props} fill="white" resizable={false} iconURL={EntrySVG}/>;
});
export const ExitComponent: React.FC<TransformableComponentProps<Exit>> = observer((props) => {
    return <TransformableComponent {...props} fill="white" resizable={false} iconURL={ExitSVG}/>;
});
