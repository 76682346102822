import React from "react";
import {
    Box,
    Container, Paper,
} from "@mui/material";
import {observer} from "mobx-react-lite";

import GlobalAppBar from "ui/components/GlobalAppBar";
import {useDataStore} from "store/DataStore";
import EditableList from "ui/components/EditableList";


const ProductsList: React.FC = observer(() => {
    const dataStore = useDataStore();
    return <Box p={2}>
        <Paper>
            <EditableList label="Product name"
                         data={dataStore.products}
                         itemGetter={item => ({
                             key: item.id,
                             texts: [{
                                 primary: item.name,
                                 secondary: item.id
                             }]
                         })}
                         onAdd={(s) => {dataStore.addProduct(s); return true;}}
                         onDelete={(product) => dataStore.removeProduct(product.id)}>
                Product List
            </EditableList>
        </Paper>
    </Box>;
});

const ProductGroupsList: React.FC = observer(() => {
    const dataStore = useDataStore();
    return <Box p={2}>
        <Paper>
            <EditableList label="Product group name"
                         data={dataStore.productGroups}
                         itemGetter={item => ({
                             key: item.id,
                             texts: [{
                                 primary: item.name,
                                 secondary: item.id
                             }]
                         })}
                         onAdd={(s) => {dataStore.addProductGroup(s); return true;}}
                         onDelete={(productGroup) => dataStore.removeProductGroup(productGroup.id)}>
                Product Group List
            </EditableList>
        </Paper>
    </Box>;
});

const ProductsPage: React.FC = () => {
    return <>
        <GlobalAppBar/>
        <Container maxWidth="xl" sx={{flex: "1", overflow: "hidden"}}>
            <Box sx={{height: "100%"}}>
                <ProductsList />
                <ProductGroupsList />
            </Box>
        </Container>
    </>;
}

export default ProductsPage;
