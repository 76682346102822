import {RootStore, useRootStore} from "store/RootStore";
import {Market, Shelf} from "store/data/Market";
import {makeAutoObservable, reaction} from "mobx";
import {Product, ProductGroup} from "store/data/Products";
import {Id} from "store/data/ID";

export interface ISerializable {
    serialize() : object;
}

export class DataStore {
    private root: RootStore;
    private i: number = 0;

    markets: Market[] = [];

    // master data
    productGroups: ProductGroup[] = [];
    products: Product[] = [];

    addProduct(name: string) {
        this.products.push(new Product(name));
    }

    removeProduct(id: Id) {
        const ix = this.products.findIndex((p) => p.id === id);
        if (ix >= 0)
            this.products.splice(ix, 1);
    }

    findProduct(id: Id): Product | undefined {
        return this.products.find((p) => p.id === id);
    }

    addProductGroup(name: string) {
        this.productGroups.push(new ProductGroup(name));
    }

    removeProductGroup(id: Id) {
        const ix = this.productGroups.findIndex((p) => p.id === id);
        if (ix >= 0)
            this.productGroups.splice(ix, 1);
    }

    findProductGroup(id: Id): ProductGroup | undefined {
        return this.productGroups.find((p) => p.id === id);
    }

    addTestMarket() {
        const shelves = [
            new Shelf({x: 1+this.i*5, y: 3+this.i*5})
        ]
        this.markets.push(new Market("test market " + (this.i++), {width: 50, height: 50}, shelves));
    }

    exportAll() {
        this.exportProducts();
        this.exportProductGroups();
        this.exportMarkets();

        alert("Exported to local storage successfully!");
    }

    exportProducts() {
        console.log("exportProducts");
        const products = this.products.map((product) => product.serialize());
        localStorage.setItem("shopping-products", JSON.stringify(products));
    }

    exportProductGroups() {
        console.log("exportProductGroups");
        const productGroups = this.productGroups.map((productGroup) => productGroup.serialize());
        localStorage.setItem("shopping-productGroups", JSON.stringify(productGroups));
    }

    exportMarkets() {
        console.log("exportMarkets");
        const markets = this.markets.map((market) => market.serialize());
        localStorage.setItem("shopping-markets", JSON.stringify(markets));
    }

    importAll() {
        const markets = localStorage.getItem("shopping-markets");
        if (markets)
            this.markets = (JSON.parse(markets) as []).map((s) => Market.deserialize(s));
        const products = localStorage.getItem("shopping-products");
        if (products)
            this.products = (JSON.parse(products) as []).map((s) => Product.deserialize(s));
        const productGroups = localStorage.getItem("shopping-productGroups");
        if (productGroups)
            this.productGroups = (JSON.parse(productGroups) as []).map((s) => ProductGroup.deserialize(s));
    }

    constructor(root: RootStore) {
        this.root = root;

        // load from localstorage on load
        this.importAll();

        makeAutoObservable(this);

        // setup automatic store to localstorage
        reaction(
            () => JSON.stringify(this.products.map((product) => product.serialize())),
            (_) => this.exportProducts()
        );
        reaction(
            () => JSON.stringify(this.productGroups.map((productGroup) => productGroup.serialize())),
            (_) => this.exportProductGroups()
        );
        reaction(
            () => JSON.stringify(this.markets.map((market) => market.serialize())),
            (_) => this.exportMarkets()
        );

        /** TODO test data **/
        /*const shelves = [
            {"position":{"x":320,"y":220},"rotation":0,"size":{"width":100,"height":20}},
            {"position":{"x":660,"y":400},"rotation":0,"size":{"width":100,"height":20}},
            {"position":{"x":540,"y":160},"rotation":0,"size":{"width":100,"height":20}},
            {"position":{"x":120,"y":340},"rotation":0,"size":{"width":100,"height":20}}
        ].map((s) => Shelf.deserialize(s));
        this.markets.push(new Market("test data market 1", shelves));*/


        /*
        const clazz = this;
        makePersistable(this, {name: "DataStore", properties: [
                {
                    key: 'markets',
                    serialize(v) => (v .serialize()
                }
            ], storage: window.localStorage});
         */
    }
}

export function useDataStore() {
    return useRootStore().dataStore;
}
