import React from 'react';
import {Route, Routes} from "react-router-dom";

import MarketPage from "ui/page/MarketPage";
import ProductsPage from "ui/page/ProductsPage";
import GoShoppingPage from "ui/page/GoShoppingPage";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './App.css';


const App: React.FC = () => {
    return <div className="App-Frame">
        <Routes>
            <Route path="/" element={<GoShoppingPage />} />
            <Route path="/edit" element={<MarketPage />} />
            <Route path="/products" element={<ProductsPage />} />
        </Routes>
    </div>;
};

export default App;
